import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import marked from "marked";
class ContactUs extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const [pageData] = get(this, "props.data.allContentfulContact.edges");
    const [banner] = get(this, "props.data.allContentfulBanner.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet
            title={`Contact us - ${siteTitle}`}
            meta={[
              {
                name: "description",
                content:
                  "Contact us for more infos or to arrange a visit - Aoga Amata Newtown",
              },
              { name: "keywords", content: "daycare, wellington, newtown" },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Hero data={banner.node} />
          <div className="wrapper">
            <div
              className="section-content"
              dangerouslySetInnerHTML={{
                __html: marked(pageData.node.content.content),
              }}
            ></div>
            <br />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34893.80502401372!2d174.76983116338616!3d-41.32181940158653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x59d481929e4c3815!2sA&#39;Oga%20Amata%20Newtown!5e0!3m2!1sfr!2snz!4v1598152615488!5m2!1sfr!2snz"
              width="100%"
              height="450"
              title="A'oga Amata Location"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            ></iframe>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContactUs;

export const pageQuery = graphql`
  query ContactUsQuery {
    allContentfulBanner(
      filter: { contentful_id: { eq: "jpxFz9RmMOvkUWNyvKxJY" } }
    ) {
      edges {
        node {
          title
          heroImage: image {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    allContentfulContact {
      edges {
        node {
          title
          content {
            content
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
